import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { getListingsById } from '../../ducks/marketplaceData.duck';

import { ASSET_NAME } from './LandingPage.duck';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    config,
    routeConfiguration,
    intl,
    history,
    location,
    listings,
  } = props;

  return (
    <PageBuilder
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      listings={listings}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const EnhancedLandingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <LandingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  const { queryListingsInProgress, queryListingsError, listingRefs } = state.LandingPage;
  const serviceListingsRefs = listingRefs?.service || [];
  const eventListingsRefs = listingRefs?.event || [];
  const productListingsRefs = listingRefs?.product || [];
  const podcastListingsRefs = listingRefs?.podcast || [];
  const zenscapeListingsRefs = listingRefs?.zenscape || [];
  const serviceListings = getListingsById(state, serviceListingsRefs);
  const eventListings = getListingsById(state, eventListingsRefs);
  const productListings = getListingsById(state, productListingsRefs);
  const podcastListings = getListingsById(state, podcastListingsRefs);
  const zenscapeListings = getListingsById(state, zenscapeListingsRefs);
  const listings = {
    service: serviceListings,
    event: eventListings,
    product: productListings,
    podcast: podcastListings,
    zenscape: zenscapeListings,
  };
  return {
    pageAssetsData,
    inProgress,
    error,
    queryListingsInProgress,
    queryListingsError,
    listings,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(EnhancedLandingPage);

export default LandingPage;
